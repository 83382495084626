import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import { useState, useEffect } from "react";
import Card from "../../Elements/Card";
import Footer, { FooterTab, MobileFooter } from "../GreenHome/Footer";
import useFindDevice from "../CustomHooks/FindDevice";

function Rulse() {
  const navigate = useNavigate();
  const { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();

  const redirectToLogin = () => {
    navigate("/login");
  };

  const redirectToRegister = () => {
    navigate("/signup");
  };

  const redirectHome = () => {
    navigate("/home");
  };

  return (
    <>
      {isMob === true ? (
        <div
          className={`font-interFont overflow-auto ${
            isMob
              ? "bg-[url('../public/card/home-screen-mobile-bg.svg')]"
              : "bg-[url('../public/card/home-bg.svg')]"
          } bg-cover`}
        >
          <div className="mb-[50px] mobile-s:h-[750px] mobile-l:h-[850px] w-full p-5 font-Inter">
            <div className="flex w-full p-3">
              <div className="w-[50%]">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className={`w-[50%] ml-[10%] mt-[4%] flex float-right`}>
                {!isLogedIn ? (
                  <button
                    className=" border-[1px] border-[#00C05B] ml-2 rounded-[4px] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]"
                    onClick={redirectToRegister}
                  >
                    Register
                  </button>
                ) : null}

                <button
                  className={`w-[90px] h-[35px] text-[14px] font-[500] bg-[#00C05B] text-[#002427] ${
                    !isLogedIn ? "ml-5" : "ml-14"
                  } rounded-[4px]`}
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div className="mt-[2%]">
                <h2 className="text-[#FFFFFF] text-[32px] text-center">
                  Rules
                </h2>
              </div>

              <Card className="m-auto h-[65.6vh] overflow-auto mt-5 pl-8 pt-12 pb-12 pr-8 flex flex-col justify-between w-[100%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px] relative">
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  Rules of MoneyBall (the game)
                </h4>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  1. By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  2. The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  3. Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  4. If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  5. The participant shall join any round (active/open round) of
                  the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  6. The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match then that pick is
                  eliminated.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  If your pick is successful then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  7. Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  8. Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks then they are
                  treated independently.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>

                <p className="text-[15px] text-[#0000005A] font-semibold">
                  9. If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  10. Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  11. Once a pick is made it can be changed up till the deadline
                  of that match week.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  12. If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  13. Any matches played out of the normal schedule or that were
                  previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  14. A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  15. The Company reserves the right, in its sole discretion, to
                  refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  16. The Company reserves the right, in its sole discretion, to
                  disqualify any participant's account who has breached any of
                  these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  17. SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute, illness,
                  act of God, natural disaster, adverse weather conditions, war,
                  riot, accident, public health concern in relation to potential
                  infection, epidemic or pandemic, malicious damage, fire, flood
                  and/or storm, compliance with law or governmental order, rule,
                  regulation or direction, breakdown of plant, machinery or
                  transportation. SSS are not responsible for any loss or damage
                  caused to you as a result of any of these occurrences.
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  18. The eligibility to participate is solely at the discretion
                  of the Company and we reserve the right to block or not accept
                  any registration
                </p>
                <p className="text-[15px] text-[#0000005A] font-semibold">
                  19. The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>
              </Card>
            </div>
          </div>

          <MobileFooter />
        </div>
      ) : isTab ? (
        <div className="overflow-auto bg-[url('../public/card/tab-bg.svg')] bg-cover h-[100vh]">
          <div className="h-[100vh] w-full p-5 font-Inter">
            <div className="flex w-full items-center px-3 pb-5 font-interFont">
              <div className="w-[44%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome} />
              </div>
              <div className="w-[56%] justify-end mr-[1%] mt-[1%] flex float-right ">
                {!isLogedIn ? (
                  <>
                    {" "}
                    <p className="text-[#FFFFFF] mt-4">Not a member yet ?</p>
                    <p
                      className="text-[#00C05B] font-[18px] mt-4 ml-2 cursor-pointer"
                      onClick={redirectToRegister}
                    >
                      Register Now
                    </p>
                  </>
                ) : null}

                <button
                  className="w-[90px] h-[35px] mt-2 text-[14px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            {/* <div> */}
            <div className="mt-[2%]">
              <h2 className="text-[#FFFFFF] text-[48px] text-center font-interFont">
                Rules
              </h2>
            </div>

            <Card className="m-auto h-[70vh] font-interFont overflow-auto pl-12 pt-12 pb-12 pr-12 flex flex-col justify-between w-[95%] my-[2%] bg-[#FFFFFF] shadow-md rounded-[12px]">
              <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                Rules of MoneyBall (the game)
              </h4>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                1. By accepting the Terms and Conditions, you agree to the
                Competition Rules, and it will be legally binding on you.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                2. The rules of MoneyBall are the same for all MoneyBall games.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                3. Using each account a participant can join any MoneyBall round
                with one or two picks. Two is the maximum per participant in a
                single round.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                4. If a participant is playing with two picks each pick is
                treated completely independently in terms of playing the game
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                5. The participant shall join any round (active/open round) of
                the competition up until the deadline of that round. The
                deadline of each round and each match week within the round is
                set at 1 hour prior to the commencement of the first match in
                that match week
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                6. The objective of the game is to assign each of your picks to
                a team in that week’s fixture list who the participant thinks
                they win their match. If the selected team wins their match that
                pick progresses to the following match week. If the selected
                team draws or loses their match then that pick is eliminated.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                and Liverpool win then Pick 1 is eliminated and Pick 2
                progresses.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                If your pick is successful then you progress to the following
                week and must repeat the process.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                7. Once a team is picked it cannot be chosen again under the
                same pick until the round is over.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                Example: Since Pick 2 above selected Liverpool and they won
                their match, then that player can no longer choose Liverpool
                under Pick 2 for the rest of the round.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                8. Once again, and as indicated in point 5.4 above, if a
                participant is playing in a round with two picks then they are
                treated independently.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                Using the same example in point 6 above, let’s assume both
                Arsenal & Liverpool have won their respective matches, in that
                case Pick 1 will no longer be able to choose Arsenal again until
                the round is over, however Pick 2 can still use Arsenal since it
                hasn’t been used before.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                Consequently, since the two entries are treated independently a
                player can pick the same team for both their picks as long as he
                hasn’t used them previously in the same round.
              </p>

              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                9. If a pick is not submitted before the deadline, that pick
                will automatically be assigned to the lowest ranked team in the
                league table that is available for that pick. If a participant
                is playing with two picks and misses the deadline on both, then
                one pick will be assigned to the lowest ranked team available
                from the league table while the second pick will be assigned to
                the second to last available team.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                Example: Sheffield and Burnley are ranked 20th and 19th in the
                Premier League coming into the match week. A participant who is
                playing with two picks misses the deadline. In that case, Pick 1
                will automatically select Sheffield while Pick 2 will
                automatically select Burnley. Let’s assume that participant had
                already picked Burnley under Pick 2 in a prior match week within
                that same round. In that case Pick 2 will automatically select
                the 18th ranked team in the table and so on.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                10. Each pick is only allowed one default per round. The second
                default within the same round will result in automatic
                elimination.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                11. Once a pick is made it can be changed up till the deadline
                of that match week.
              </p>
              <p className="text-[15px] font-interFont text-[#0000005A] font-semibold">
                12. If a match is postponed prior to the deadline lapsing the
                player must re-pick by choosing a team from the remaining
                fixtures that match week. If the match is postponed after the
                deadline has passed then any picks for that fixture will be
                deemed successful and the players involved will not be allowed
                to pick those same teams again in that round.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                13. Any matches played out of the normal schedule or that were
                previously postponed will not be part of the fixtures to select
                from and will be ignored.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                14. A match week will only be considered for selection if it has
                a minimum of 8 fixtures. Match weeks that have less will be
                skipped in the schedule.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                15. The Company reserves the right, in its sole discretion, to
                refuse to accept participation that is incomplete,
                indecipherable, offensive, does not comply with these T&Cs, or
                contravenes any applicable laws or regulation.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                16. The Company reserves the right, in its sole discretion, to
                disqualify any participant's account who has breached any of
                these T&Cs, has engaged in unlawful or improper conduct or
                otherwise acts to cheat or undermine the fairness of the
                Competition/s by, for example, tampering with, or using or
                exploiting errors in, the participation process to obtain a
                competitive advantage over other entrants.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                17. SureShotSports FZ-LLC, if necessary to do so, reserve the
                right to suspend, amend, void or even cancel a game. This may be
                necessary for reasons beyond our reasonable control including,
                but not limited to strike, labour dispute, illness, act of God,
                natural disaster, adverse weather conditions, war, riot,
                accident, public health concern in relation to potential
                infection, epidemic or pandemic, malicious damage, fire, flood
                and/or storm, compliance with law or governmental order, rule,
                regulation or direction, breakdown of plant, machinery or
                transportation. SSS are not responsible for any loss or damage
                caused to you as a result of any of these occurrences.
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                18. The eligibility to participate is solely at the discretion
                of the Company and we reserve the right to block or not accept
                any registration
              </p>
              <p className="text-[15px] text-[#0000005A] font-semibold">
                19. The Company assumes no responsibility for any failure to
                receive participation or for inaccurate information or any loss,
                damage, or injury because of technical or telecommunications
                problems, including security breaches. If such problems arise,
                the Company may (where necessary with the approval of the
                relevant authority) modify, cancel, terminate, or suspend the
                Competition/s.
              </p>
            </Card>
            {/* </div> */}
          </div>
          <FooterTab />
        </div>
      ) : (
        <div className="overflow-auto bg-[url('../public/card/home-bg.svg')] bg-cover h-[100vh]">
          <div
            className="h-[100vh] w-full p-5 
          laptop-min:[1200px] mb-[50px] laptop-l:h-[857px] laptop-s:h-[800px] font-Inter"
          >
            <div className="flex w-full p-3">
              <div className="w-[75%] ml-[1.5%] laptop-l:w-[70%] laptop-s:w-[58%] ">
                <img src={Logo} alt="pickNdPrizes" onClick={redirectHome}></img>
              </div>
              <div className="w-[25%] justify-end mr-[2%] mt-[1%] flex float-right laptop-l:w-[30%] laptop-s:w-[42%]">
                {!isLogedIn ? (
                  <>
                    {window.innerWidth <= 500 ? (
                      ""
                    ) : (
                      <p className="text-[#FFFFFF] mt-2">Not a member yet ?</p>
                    )}
                    {/* <p className='text-[#FFFFFF] mt-2'>Not a member yet ?</p> */}

                    {window.innerWidth <= 500 ? (
                      <button className=" border-[1px] border-[#00C05B] w-[95px] h-[37px] text-[14px] cursor-pointer text-[#00C05B]">
                        Register
                      </button>
                    ) : (
                      <p
                        className="text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer"
                        onClick={redirectToRegister}
                      >
                        Register Now
                      </p>
                    )}
                  </>
                ) : null}
                {/* <p className='text-[#00C05B] font-[18px] mt-2 ml-2 cursor-pointer'>Register Now</p> */}
                <button
                  className="w-[90px] h-[35px] text-[18px] font-[500] bg-[#00C05B] text-[#002427] ml-5 rounded-[4px]"
                  onClick={redirectToLogin}
                >
                  {!isLogedIn ? "Login" : "Play now"}
                </button>
              </div>
            </div>

            <hr className="w-[95%] opacity-[.2] m-auto"></hr>
            <div>
              <div>
                <h2 className="text-[#FFFFFF] text-[48px] text-center">
                  Rules
                </h2>
              </div>

              <Card className="m-auto laptop-s:h-[600px] laptop-l:h-[65vh] desktop:h-[80vh] overflow-auto  pl-16 pt-12 pb-12 pr-16 flex flex-col justify-between w-[95%] my-[1%] bg-[#FFFFFF] shadow-md rounded-[12px] ">
                <h4 className="text-[22px] mt-[3%] text-[#000000] font-semibold">
                  Rules of MoneyBall (the game)
                </h4>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  1. By accepting the Terms and Conditions, you agree to the
                  Competition Rules, and it will be legally binding on you.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  2. The rules of MoneyBall are the same for all MoneyBall
                  games.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  3. Using each account a participant can join any MoneyBall
                  round with one or two picks. Two is the maximum per
                  participant in a single round.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  4. If a participant is playing with two picks each pick is
                  treated completely independently in terms of playing the game.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  5. The participant shall join any round (active/open round) of
                  the competition up until the deadline of that round. The
                  deadline of each round and each match week within the round is
                  set at 1 hour prior to the commencement of the first match in
                  that match week
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  6. The objective of the game is to assign each of your picks
                  to a team in that week’s fixture list who the participant
                  thinks they win their match. If the selected team wins their
                  match that pick progresses to the following match week. If the
                  selected team draws or loses their match then that pick is
                  eliminated.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  Example: Pick 1: Arsenal, and Pick 2: Liverpool. Arsenal draw
                  and Liverpool win then Pick 1 is eliminated and Pick 2
                  progresses.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  If your pick is successful then you progress to the following
                  week and must repeat the process.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  7. Once a team is picked it cannot be chosen again under the
                  same pick until the round is over.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  Example: Since Pick 2 above selected Liverpool and they won
                  their match, then that player can no longer choose Liverpool
                  under Pick 2 for the rest of the round.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  8. Once again, and as indicated in point 5.4 above, if a
                  participant is playing in a round with two picks then they are
                  treated independently.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  Using the same example in point 6 above, let’s assume both
                  Arsenal & Liverpool have won their respective matches, in that
                  case Pick 1 will no longer be able to choose Arsenal again
                  until the round is over, however Pick 2 can still use Arsenal
                  since it hasn’t been used before.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  Consequently, since the two entries are treated independently
                  a player can pick the same team for both their picks as long
                  as he hasn’t used them previously in the same round.
                </p>

                <p className="text-[17px] text-[#0000005A] font-semibold">
                  9. If a pick is not submitted before the deadline, that pick
                  will automatically be assigned to the lowest ranked team in
                  the league table that is available for that pick. If a
                  participant is playing with two picks and misses the deadline
                  on both, then one pick will be assigned to the lowest ranked
                  team available from the league table while the second pick
                  will be assigned to the second to last available team.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  Example: Sheffield and Burnley are ranked 20th and 19th in the
                  Premier League coming into the match week. A participant who
                  is playing with two picks misses the deadline. In that case,
                  Pick 1 will automatically select Sheffield while Pick 2 will
                  automatically select Burnley. Let’s assume that participant
                  had already picked Burnley under Pick 2 in a prior match week
                  within that same round. In that case Pick 2 will automatically
                  select the 18th ranked team in the table and so on.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  10. Each pick is only allowed one default per round. The
                  second default within the same round will result in automatic
                  elimination.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  11. Once a pick is made it can be changed up till the deadline
                  of that match week.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  12. If a match is postponed prior to the deadline lapsing the
                  player must re-pick by choosing a team from the remaining
                  fixtures that match week. If the match is postponed after the
                  deadline has passed then any picks for that fixture will be
                  deemed successful and the players involved will not be allowed
                  to pick those same teams again in that round.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  13. Any matches played out of the normal schedule or that were
                  previously postponed will not be part of the fixtures to
                  select from and will be ignored.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  14. A match week will only be considered for selection if it
                  has a minimum of 8 fixtures. Match weeks that have less will
                  be skipped in the schedule.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  15. The Company reserves the right, in its sole discretion, to
                  refuse to accept participation that is incomplete,
                  indecipherable, offensive, does not comply with these T&Cs, or
                  contravenes any applicable laws or regulation.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  16. The Company reserves the right, in its sole discretion, to
                  disqualify any participant's account who has breached any of
                  these T&Cs, has engaged in unlawful or improper conduct or
                  otherwise acts to cheat or undermine the fairness of the
                  Competition/s by, for example, tampering with, or using or
                  exploiting errors in, the participation process to obtain a
                  competitive advantage over other entrants.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  17. SureShotSports FZ-LLC, if necessary to do so, reserve the
                  right to suspend, amend, void or even cancel a game. This may
                  be necessary for reasons beyond our reasonable control
                  including, but not limited to strike, labour dispute, illness,
                  act of God, natural disaster, adverse weather conditions, war,
                  riot, accident, public health concern in relation to potential
                  infection, epidemic or pandemic, malicious damage, fire, flood
                  and/or storm, compliance with law or governmental order, rule,
                  regulation or direction, breakdown of plant, machinery or
                  transportation. SSS are not responsible for any loss or damage
                  caused to you as a result of any of these occurrences.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  18. The eligibility to participate is solely at the discretion
                  of the Company and we reserve the right to block or not accept
                  any registration.
                </p>
                <p className="text-[17px] text-[#0000005A] font-semibold">
                  19. The Company assumes no responsibility for any failure to
                  receive participation or for inaccurate information or any
                  loss, damage, or injury because of technical or
                  telecommunications problems, including security breaches. If
                  such problems arise, the Company may (where necessary with the
                  approval of the relevant authority) modify, cancel, terminate,
                  or suspend the Competition/s.
                </p>
              </Card>
            </div>
          </div>
          <Footer componnent="rules" />
        </div>
      )}
    </>
  );
}

export default Rulse;
