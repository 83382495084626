import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Elements/Card";
import ball from "../../../assets/images/ball.png";
import Table from "../../CommonComponent/Table";
import { useNavigate } from "react-router-dom";
import Button from "../../../Elements/Button";

import Pagination from "../../CommonComponent/Pagination";
import { updatePagination } from "../../MyPicks/myPick.slice";
import { formatAsIndianCurrency } from "../../../utils/currencyFormat";

import {
  mobilesecondTableHeader,
  mobileTableHeader,
} from "../../TableHeader/TableHeader";
import { updatePurchasePagination } from "../../myPurchases/myPurchases.slice";
import { updateWinningPagination } from "../../MyWinnings/myWinnings.slice";
import { utcTimetoLocalTimeWithzone } from "../../../utils/convertTime";

import greenDat from "../../../assets/card/green-dat.svg";
import redDat from "../../../assets/card/red-dat.svg";

import newBackArrow from "../../../assets/card/new-back-arrow.svg";

import viePickBall from "../../../assets/card/view-ball.svg";

function ActiveTeam({
  myPickInfo,
  isMobile,
  isLabtop,
  leagueInfo,
  tableHeader,
  tableBody,
  mobileMyPick,
  mobileMyPurchase,
  mobileMyWinning,
  pagination,
  component,
  secondTableHeader,
  secondTableBody,
  historyMobileTable,
  potInfoMobileTable,
  currencyType,
  mobileTransactionHistory,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localPage = 0;
  const backBtnHandler = (component) => () => {
    if (component === "history") {
      navigate("/myPicks");
    } else if (component === "widthdrawHistory") {
      navigate("/myWinnings");
    } else {
      navigate("/dashboard");
    }
  };
  // ** pagination **//
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    pageSize: 10,
    totalRecord: 0,
  });

  const paginationDetail = useSelector((state) => {
    if (component === "myPicksMenu") {
      return {
        currentPage: state.myPicks.pagination.currentPage,
        pageSize: state.myPicks.pagination.pageSize,
        totalRecord: state.myPicks.pagination.totalRecord,
      };
    } else if (component === "myPurchases") {
      return {
        currentPage: state.myPurchases.pagination.currentPage,
        pageSize: state.myPurchases.pagination.pageSize,
        totalRecord: state.myPurchases.pagination.totalRecord,
      };
    } else if (component === "myWinnings") {
      return {
        currentPage: state.myWinnings.pagination.currentPage,
        pageSize: state.myWinnings.pagination.pageSize,
        totalRecord: state.myWinnings.pagination.totalRecord,
      };
    }
  });

  useEffect(() => {
    setPaginationInfo((prev) => ({
      currentPage: paginationDetail?.currentPage,
      pageSize: paginationDetail?.pageSize,
      totalRecord: paginationDetail?.totalRecord,
    }));
  }, [
    paginationDetail?.currentPage,
    paginationDetail?.pageSize,
    paginationDetail?.totalRecord,
  ]);

  // ** pagination **//
  const handleClick = (potId, pick, status) => {
    navigate(`/myHistories/${potId}/${pick}/${status}`);
  };

  const [hasMore, setHasMore] = useState(true);

  const elementRef = useRef(null);

  function onIntersection(entries) {
    const firstEntries = entries[0];
    const { totalRecord, currentPage, pageSize } = paginationInfo;

    let currentRec = currentPage * pageSize;
    const isNextPage = currentRec < totalRecord;
    if (component === "myPurchases") {
      if (firstEntries.isIntersecting && hasMore && isNextPage) {
        dispatch(
          updatePurchasePagination({
            page: paginationInfo.currentPage + 1,
            pageSize: 10,
          })
        );
      }
    } else if (component === "myWinnings") {
      if (firstEntries.isIntersecting && hasMore && isNextPage) {
        dispatch(
          updateWinningPagination({
            page: paginationInfo.currentPage + 1,
            pageSize: 10,
          })
        );
      }
    } else if (component === "myPicksMenu") {
      if (firstEntries.isIntersecting && hasMore && isNextPage) {
        dispatch(
          updatePagination({
            page: paginationInfo.currentPage + 1,
            pageSize: 10,
          })
        );
      }
    }
  }

  useEffect(() => {
    let observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [tableBody, mobileMyPick]);

  const transactionHandler = () => {
    navigate("/widthdrawHistory");
  };

  return (
    <div>
      {!isMobile &&
      component != "myPicksMenu" &&
      component != "profile" &&
      component != "myPurchases" &&
      component != "history" &&
      component != "myWinnings" &&
      component != "widthdrawHistory" ? (
        <>
          <div className="flex">
            <div className="flex w-[15%] gap-4 items-center justify-end">
              {/* <div
                className="flex gap-4 items-center"
                onClick={backBtnHandler(component)}
              >
                <img src={newBackArrow} className="h-5 w-5 cursor-pointer" />
                <Button className="text-[#FFFFFF] font-interFont font-wieght-[500] justify-center text-[22px]">
                  Back
                </Button>
              </div> */}
            </div>
            <div
              className={`${
                !isLabtop
                  ? "bg-[url('../public/images/new-tab-img.png')]"
                  : "bg-[url('../public/images/view-detail-rectangle.png')]"
              }
         bg-cover h-[50px]
         tablet:!w-[65%]
         laptop-s:!w-[70%]
         laptop-s:!h-[50px]
         desktop:!h-[70px]
        `}
            >
              <div className="flex flex-1 ml-[7%] mt-[1%] items-center justify-center gap-[1%]">
                <p className="text-[22px]">
                  {leagueInfo?.league ? leagueInfo?.league : ""}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex mobile-s:px-6 tablet:px-14 items-center justify-between">
          <div className="mobile-s:mt-[4%] tablet:mt-[5%] mb-[2%] flex gap-4 items-center">
            <img
              src={newBackArrow}
              className="h-[20px] cursor-pointer w-[15px] ml-[3%]"
              onClick={backBtnHandler(component)}
            />
            <p
              className="text-[#fff] cursor-pointer text-nowrap text-[18px] text-center justify-center"
              onClick={backBtnHandler(component)}
            >
              {component === "myPicksMenu" ? (
                "My Picks"
              ) : component === "myPot" ? (
                <p className="text-center">Active Rounds</p>
              ) : component === "jackPot" ? (
                <p className="text-center">Split Rounds</p>
              ) : component === "history" ? (
                "Back"
              ) : component === "myPurchases" ? (
                "My Purchases"
              ) : component === "myWinnings" ? (
                "My Winnings"
              ) : component === "widthdrawHistory" ? (
                "Withdrawal History"
              ) : (
                ""
              )}
            </p>
          </div>
          {component === "myWinnings" && (
            <Button
              className={`text-nowrap mobile-s:mt-[3%] tablet:mt-[2%] laptop-min-s:mt-[3%]
                 laptop-min:mt-[4%]
                laptop-l:mt-[4%]
                bg-[#009B36] text-[white] cursor-pointer 
                flex items-center gap-[10px]
    rounded-[8px] mobile-s:text-[10px] tablet:text-[14px] mobile-m:text-[14px] mobile-s:p-2 
     laptop-l:py-2 laptop-s:p-3 tablet:p-3
    focus:outline-none focus:shadow-outline`}
              onClick={transactionHandler}
            >
              Withdrawal History
            </Button>
          )}
        </div>
      )}
      {isMobile && (component === "myPot" || component === "jackPot") && (
        <Card
          className={`w-[95%] m-auto pb-[5%] mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%]
                    tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
                    laptop-s:w-full laptop-l:w-full laptop-min:w-full
                    laptop-min:mb-[3%] desktop:mb-[2%] bg-[#FFFFFF]
                    rounded-[6px] desktop:w-full`}
        >
          <div className="m-auto">
            <div
              className="flex header-container
                   p-[1px]  m-[3px]
                  rounded-tl-[6px] rounded-tr-[6px]"
            >
              <div className="flex px-[3%] py-[3%] mt-[3px] bg-[#00AB26] w-full rounded-tl-[6px] rounded-tr-[6px]">
                <div className="flex flex-1">
                  <img src={viePickBall} />
                  <p className="text-[#F8F8F8] text-[18px] pl-2">
                    {" "}
                    {leagueInfo?.league}
                  </p>
                </div>

                <div className="text-[#FFFFFF] text-[18px]">
                  MW - {mobileMyPick[0]?.matchWeek}
                </div>
              </div>
            </div>

            <div className="px-[3%] m-[3px]">
              <div className="flex w-full mb-[2%]">
                <p className="flex w-[42%] text-[14px]">Round ID</p>
                <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                  {mobileMyPick[0]?.pot_id}
                </p>
              </div>

              <div className="flex w-full mb-[2%]">
                <p className="flex w-[42%] text-[14px]">Pick ID</p>
                <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                  {mobileMyPick[0]?.pick_id}
                </p>
              </div>

              <div className="flex w-full mb-[2%]">
                <p className="flex w-[42%] text-[14px]">Round Value</p>
                <p className="ml-[6%] text-[14px] font-semibold">
                  {" "}
                  {mobileMyPick[0]?.potValue}
                </p>
              </div>

              <div className="flex w-full mb-[2%]">
                <p className="flex w-[44%] text-[14px]">No. of Picks</p>
                <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold">
                  {mobileMyPick[0]?.no_of_picks}
                </p>
              </div>

              <div className="flex w-full mb-[2%]">
                <p className="flex w-[42%] text-[14px]">Round Status</p>
                <p
                  className={`flex items-center gap-1 ml-[4%] text-[14px] ${
                    mobileMyPick[0].pick_status === "Success"
                      ? "bg-[#00A34D0F] px-4 rounded-[5px] text-[14px] font-interFont text-[#00A34D]"
                      : mobileMyPick[0].pick_status === "Failed"
                      ? "bg-[#FF49560F] px-4 rounded-[5px] text-[#FF4956] text-[14px] font-interFont"
                      : null
                  } font-semibold text-nowrap`}
                >
                  {mobileMyPick[0].pick_status === "Success" ? (
                    <img src={greenDat} className="h-2 w-2" />
                  ) : mobileMyPick[0].pick_status === "Failed" ? (
                    <img src={redDat} className="h-2 w-2" />
                  ) : null}
                  {mobileMyPick[0].pick_status === "Success"
                    ? "Success"
                    : mobileMyPick[0].pick_status === "Failed"
                    ? "Failed"
                    : mobileMyPick[0].pick_status}
                </p>
              </div>
            </div>
          </div>
        </Card>
      )}

      {isMobile && component === "myPurchases" && (
        <>
          <div
            className={`flex flex-wrap m-auto gap-4 mobile-s:mt-[5%] tab-content w-[95%]  rounded-[6px]`}
            id="mobile-container"
          >
            {" "}
            <>
              {mobileMyPurchase.length > 0 ? (
                mobileMyPurchase.map((ele, i) => {
                  const date = utcTimetoLocalTimeWithzone(ele.updated_at).split(
                    " "
                  );
                  const time = `${date[3].split(":")[0]}:${
                    date[3].split(":")[1]
                  }`;
                  let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
                  return (
                    <Card
                      className={`w-[95%]  m-auto pb-[5%] mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%]
       tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
       laptop-s:w-full laptop-l:w-full laptop-min:w-full
       laptop-min:mb-[3%] desktop:mb-[2%] ${
         component === "myPicksMenu"
           ? `bg-[#00387A] text-white`
           : "bg-[#FFFFFF]"
       }
       rounded-[6px] desktop:w-full`}
                    >
                      <div className="m-auto">
                        <div
                          className="flex header-container
      p-[1px]  m-[3px]
     rounded-tl-[6px] rounded-tr-[6px]"
                        ></div>

                        <div className="px-[3%] m-[3px] mt-[5%]">
                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              Transaction ID
                            </p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                              {ele?.transaction_id ? ele?.transaction_id : "-"}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              Payment Mode
                            </p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold text-nowrap">
                              {ele?.transfer_type ? ele?.transfer_type : "-"}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              Purchase Date
                            </p>
                            <p className="ml-[6%] text-[14px] font-semibold">
                              {concatDate}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Amount Paid
                            </p>
                            <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                              {formatAsIndianCurrency(
                                ele.amount,
                                ele.currency_code
                              )}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Token Purchased
                            </p>
                            <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                              {ele.token}
                            </p>
                          </div>
                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Payment Status
                            </p>
                            <p
                              className={`flex items-center gap-1 ml-[4%] text-[14px] ${
                                ele.transaction_status === "Succcess"
                                  ? "bg-[#00A34D0F] px-4 rounded-[5px] text-[14px] font-interFont text-[#00A34D]"
                                  : ele.transaction_status === "Failed"
                                  ? "bg-[#FF49560F] px-4 rounded-[5px] text-[#FF4956] text-[14px] font-interFont"
                                  : "text-[14px] font-interFont"
                              } font-semibold text-nowrap`}
                            >
                              {ele.transaction_status === "Succcess" ? (
                                <img src={greenDat} className="h-2 w-2" />
                              ) : ele.transaction_status === "Failed" ? (
                                <img src={redDat} className="h-2 w-2" />
                              ) : (
                                ""
                              )}
                              {ele.transaction_status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })
              ) : (
                <div className="flex items-center justify-center mt-[3%] p-[2%] w-full">
                  <p className="text-white"> No purchases found yet.</p>
                </div>
              )}
            </>
          </div>
          {hasMore && (
            <div ref={elementRef} style={{ textAlign: "center" }}></div>
          )}
        </>
      )}

      {isMobile && component === "myWinnings" && (
        <>
          <div
            className={`flex flex-wrap m-auto gap-4 mobile-s:mt-[5%] tab-content w-[95%]  rounded-[6px]`}
            id="mobile-container"
          >
            {" "}
            <>
              {mobileMyWinning.length > 0 ? (
                mobileMyWinning.map((ele, i) => {
                  const date = utcTimetoLocalTimeWithzone(ele.created_at).split(
                    " "
                  );
                  const time = `${date[3].split(":")[0]}:${
                    date[3].split(":")[1]
                  }`;
                  let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
                  return (
                    <Card
                      className={`w-[95%]  m-auto pb-[5%] mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%]
       tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
       laptop-s:w-full laptop-l:w-full laptop-min:w-full
       laptop-min:mb-[3%] desktop:mb-[2%] ${
         component === "myPicksMenu"
           ? `bg-[#00387A] text-white`
           : "bg-[#FFFFFF]"
       }
       rounded-[6px] desktop:w-full`}
                    >
                      <div className="m-auto">
                        <div
                          className="flex header-container
      p-[1px]  m-[3px]
     rounded-tl-[6px] rounded-tr-[6px]"
                        ></div>

                        <div className="px-[3%] m-[3px] mt-[5%]">
                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">Pick ID</p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                              {ele.id}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">Round ID</p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold text-nowrap">
                              {ele?.round_id ? ele?.round_id : "-"}
                            </p>
                          </div>

                          {/* <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              Payment Mode
                            </p>
                            <p className="ml-[6%] text-[14px] font-semibold">
                              {ele.payment_type}
                            </p>
                          </div> */}

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Amount Won
                            </p>
                            <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                              {formatAsIndianCurrency(
                                ele.amount,
                                ele.currency_code
                              )}
                            </p>
                          </div>

                          {/* <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Payment Date
                            </p>
                            <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                              {utcTimetoLocalTimeWithzone(ele.updated_at)}
                            </p>
                          </div>
                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Payment Status
                            </p>
                            <p
                              className={`flex items-center gap-1 ml-[4%] text-[14px] ${
                                ele.payment_status
                                  ? "bg-[#00A34D0F] px-4 rounded-[5px] text-[14px] font-interFont text-[#00A34D]"
                                  : "bg-[#FF49560F] px-4 rounded-[5px] text-[#FF4956] text-[14px] font-interFont"
                              } font-semibold text-nowrap`}
                            >
                              {ele.payment_status ? (
                                <img src={greenDat} className="h-2 w-2" />
                              ) : (
                                <img src={redDat} className="h-2 w-2" />
                              )}
                              {ele.payment_status ? "Success" : "Failed"}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </Card>
                  );
                })
              ) : (
                <div className="flex items-center justify-center mt-[3%] p-[2%] w-full">
                  <p className="text-white">
                    No winnings yet. Join the game and start winning.
                  </p>
                </div>
              )}
            </>
          </div>
          {hasMore && (
            <div ref={elementRef} style={{ textAlign: "center" }}></div>
          )}
        </>
      )}

      {isMobile && component === "transactionHistory" && (
        <>
          <div
            className={`flex flex-wrap m-auto gap-4 mobile-s:mt-[5%] tab-content w-[95%]  rounded-[6px]`}
            id="mobile-container"
          >
            {" "}
            <>
              {mobileTransactionHistory.length > 0 ? (
                mobileTransactionHistory.map((ele, i) => {
                  const date = utcTimetoLocalTimeWithzone(ele.updated_at).split(
                    " "
                  );
                  const time = `${date[3].split(":")[0]}:${
                    date[3].split(":")[1]
                  }`;
                  let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
                  return (
                    <Card
                      className={`w-[95%]  m-auto pb-[5%] mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%]
       tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
       laptop-s:w-full laptop-l:w-full laptop-min:w-full
       laptop-min:mb-[3%] desktop:mb-[2%] ${
         component === "myPicksMenu"
           ? `bg-[#00387A] text-white`
           : "bg-[#FFFFFF]"
       }
       rounded-[6px] desktop:w-full`}
                    >
                      <div className="m-auto">
                        <div
                          className="flex header-container
      p-[1px]  m-[3px]
     rounded-tl-[6px] rounded-tr-[6px]"
                        ></div>

                        <div className="px-[3%] m-[3px] mt-[5%]">
                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">S.No</p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                              {i + 1}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              payment ID
                            </p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                              {ele.order_no}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[42%] text-[14px]">
                              Payment Date
                            </p>
                            <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold text-nowrap">
                              {concatDate}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Payment Amount
                            </p>
                            <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                              {formatAsIndianCurrency(
                                ele.amount_payable,
                                ele.currency_code
                              )}
                            </p>
                          </div>

                          <div className="flex w-full mb-[2%]">
                            <p className="flex w-[44%] text-[14px]">
                              Payment Status
                            </p>
                            {/* <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold text-nowrap">
                            {ele.payment_status}
                            </p> */}
                            <p
                              className={`text-wh font-semibold p-1 w-[25%] flex items-center gap-2 ml-2 rounded-[6px] ${
                                ele?.payment_status === "Failure"
                                  ? "text-[#FF4956] bg-[#FF49560F]"
                                  : ele?.payment_status === "Success"
                                  ? "text-[#00A34D] bg-[#00A34D0F]"
                                  : null
                              }`}
                            >
                              {ele?.payment_status === "Success" ? (
                                <img src={greenDat} className="h-2 w-2" />
                              ) : ele?.payment_status === "Failure" ? (
                                <img src={redDat} className="h-2 w-2" />
                              ) : null}{" "}
                              {ele?.payment_status
                                ? ele?.payment_status
                                : "Idle"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })
              ) : (
                <div className="flex items-center justify-center mt-[3%] p-[2%] w-full">
                  <p className="text-white">
                    No winnings yet. Join the game and start winning.
                  </p>
                </div>
              )}
            </>
          </div>
          {hasMore && (
            <div ref={elementRef} style={{ textAlign: "center" }}></div>
          )}
        </>
      )}

      {isMobile && component == "myPicksMenu" && component != "history" ? (
        <>
          <div
            className={`flex flex-wrap m-auto gap-4 tab-content w-[95%]  rounded-[6px]`}
            id="mobile-container"
          >
            {mobileMyPick?.length > 0 ? (
              mobileMyPick?.map((ele, i) => {
                let splitArray;
                let amount;
                if (ele?.current_pot_value) {
                  const str = ele?.current_pot_value
                    ? ele?.current_pot_value
                    : "";
                  if (str) {
                    splitArray = str.split(" ");
                  }
                  const amountString = ele?.current_pot_value;

                  if (amountString) {
                    amount = parseFloat(amountString?.match(/\d+(\.\d+)?/)[0]);
                  }
                } else {
                  amount = ele.potValue;
                }

                return (
                  <Card
                    className={`w-full pb-[5%] mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%]
                    tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
                    laptop-s:w-full laptop-l:w-full laptop-min:w-full
                    laptop-min:mb-[3%] desktop:mb-[2%] bg-[#FFFFFF]
                    rounded-[6px] desktop:w-full`}
                  >
                    <div className="m-auto">
                      <div
                        className="flex header-container
                   p-[1px]  m-[3px]
                  rounded-tl-[6px] rounded-tr-[6px]"
                      >
                        <div className="flex p-[3%] bg-[#00AB26] w-full rounded-tl-[6px] rounded-tr-[6px]">
                          <div className="flex flex-1">
                            <img src={ball} />
                            <p className="text-[#F8F8F8] text-[18px] pl-2">
                              {leagueInfo?.league}
                            </p>
                          </div>

                          <div className="text-[#FFFFFF] text-[18px]">
                            MW -{ele.last_mw}
                          </div>
                        </div>
                      </div>

                      <div className="px-[3%] m-[3px]">
                        <div className="flex w-full mb-[2%]">
                          <p className="flex w-[42%] text-[14px]">Round ID</p>
                          <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                            {ele.pot_id}
                          </p>
                        </div>

                        <div className="flex w-full mb-[2%]">
                          <p className="flex w-[42%] text-[14px]">Pick ID</p>
                          <p className="flex w-[5%] ml-[6%] text-[14px] font-semibold">
                            {ele.pick_id}
                          </p>
                        </div>

                        <div className="flex w-full mb-[2%]">
                          <p className="flex w-[42%] text-[14px]">
                            Round Value
                          </p>
                          <p className="ml-[6%] text-[14px] font-semibold">
                            {ele?.current_pot_value}
                          </p>
                        </div>

                        <div className="flex w-full mb-[2%]">
                          <p className="flex w-[44%] text-[14px]">
                            No. of Picks
                          </p>
                          <p className="flex w-[5%] ml-[4%] text-[14px] font-semibold">
                            {ele.no_of_picks}
                          </p>
                        </div>

                        <div className="flex w-full mb-[2%]">
                          <p className="flex w-[42%] text-[14px]">
                            Round Status
                          </p>
                          <p className="ml-[4%] text-[14px] font-semibold">
                            {ele.pick_status === "Lost" ? (
                              <div className="bg-[#FF49560F] flex items-center gap-1 px-3 pb-1 pt-1 rounded-[5px] laptop-l:!w-[180px] tablet-l:!w-[150px]">
                                <img src={redDat} className="h-2 w-2" />
                                <p
                                  className=" text-[#FF4956]
                               rounded-[6px]"
                                >
                                  {ele.pick_status}
                                </p>
                              </div>
                            ) : ele.pick_status === "Win" ? (
                              <div className="bg-[#00A34D26] flex items-center gap-1 px-3 pb-1 pt-1 rounded-[5px] laptop-l:!w-[180px] tablet-l:!w-[150px]">
                                <img src={greenDat} className="h-2 w-2" />
                                <p
                                  className=" text-[#00A34D]
                             rounded-[6px]"
                                >
                                  {ele.pick_status}
                                </p>
                              </div>
                            ) : ele.pick_status === "Active" ? (
                              <div className="bg-[#E6EFFB] flex items-center gap-1 px-3 pb-1 pt-1 rounded-[5px] laptop-l:!w-[180px] tablet-l:!w-[150px]">
                                {/* <img src={greenDat} className="h-2 w-2" /> */}
                                <p
                                  className="
                         rounded-[6px]"
                                >
                                  {ele.pick_status}
                                </p>
                              </div>
                            ) : (
                              <p>{ele.pick_status}</p>
                            )}
                          </p>
                        </div>
                      </div>
                      {component === "myPicksMenu" ? (
                        <Button
                          className={`bg-[#0AB159] flex justify-center w-[95%] m-auto 
                            text-[14px] hover:bg-[#0AB159] px-2
                    py-3 text-white font-bold focus:outline-none focus:shadow-outline
                    mt-[3%] rounded-[10px]`}
                          type="button"
                          onClick={() => {
                            handleClick(
                              myPickInfo[i]?.pot_id,
                              ele.pick_id,
                              ele.pick_status
                            );
                          }}
                        >
                          <p className="flex items-center justify-center gap-2">
                            <span className="text-[white] mobile-s:text-[10px] mobile-m:text-[14px] laptop-min:text-[14px] text-[14px] laptop-l:text-[14px] tablet:!text-[12px]">
                              View Picks
                            </span>
                          </p>
                        </Button>
                      ) : null}
                    </div>
                  </Card>
                );
              })
            ) : (
              <div className="flex items-center justify-center mt-[5%] p-[2%] w-full">
                <p className="text-white">
                  {" "}
                  You haven't made any picks yet. Join the game to make your
                  first pick!
                </p>
              </div>
            )}
          </div>
          {hasMore && (
            <div ref={elementRef} style={{ textAlign: "center" }}></div>
          )}
        </>
      ) : (
        <div>
          {!isMobile ? (
            <>
              <div
                className={`flex m-auto gap-4 tab-content w-[95%] rounded-[6px]`}
              >
                <Card
                  className={`w-full mobile-s:mb-[3%] 
                  mobile-m:mb-[3%] mobile-l:mb-[3%] 
            tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
            laptop-s:w-full laptop-l:w-full laptop-min:w-full
            laptop-min:mb-[3%] desktop:mb-[2%] 
            ${
              component === "myPicksMenu"
                ? `bg-[#FFFFFF] text-black`
                : "bg-[#FFFFFF]"
            }
            rounded-[6px] desktop:w-full`}
                >
                  <div className="m-auto table-container w-full">
                    <Table
                      tableName="activeUser"
                      // className="min-w-[99%] overflow-auto m-[.5%] w-[1400px] rounded-lg"
                      className="min-w-[99%] m-[.5%] rounded-lg  overflow-hidden p-4"
                      tableHeader={tableHeader}
                      tableBody={tableBody}
                      pagination={pagination}
                      component={component}
                      staticMessage={
                        component === "myPicksMenu"
                          ? "You haven't made any picks yet. Join the game to make your first pick!"
                          : component === "myPurchases"
                          ? "No purchases found yet. "
                          : component === "myWinnings"
                          ? "No winnings yet. Join the game and start winning."
                          : ""
                      }
                    />

                    {component === "history" ? (
                      <>
                        <div className="border border-1 min-w-[98%] m-auto"></div>
                        <h1 className="ml-[5%] mt-[2%] text-[22px]">
                          History of Picks
                        </h1>
                        <Table
                          tableName="historyPicks"
                          className="min-w-[95%] mt-[1%] mb-[2%] m-auto w-[1400px] rounded-lg overflow-hidden"
                          tableHeader={secondTableHeader}
                          tableBody={secondTableBody}
                          component="historyPicks"
                        />
                      </>
                    ) : null}
                  </div>
                </Card>
              </div>
              {(component === "widthdrawHistory" ||
                component === "myPicksMenu" ||
                component === "myPurchases" ||
                component === "myWinnings") &&
              pagination?.totalRecord > 0 ? (
                <Pagination pagination={pagination} component={component} />
              ) : null}
            </>
          ) : null}
        </div>
      )}

      {isMobile && component === "history" && (
        <>
          <div
            className={`flex m-auto gap-4 tab-content w-[95%] bg-[#FFFFFF] rounded-[6px]`}
          >
            <Card
              className="w-full mobile-s:mb-[3%] mobile-m:mb-[3%] mobile-l:mb-[3%] 
            tablet:w-full tablet:mb-[3%] laptop-l:mb-[1px] laptop-s:mb-[1px]
            laptop-s:w-full laptop-l:w-full laptop-min:w-full
            laptop-min:mb-[3%] desktop:mb-[2%] bg-[#FFFFFF]
            rounded-[6px] desktop:w-full"
            >
              <div className="m-auto table-container w-full">
                <Table
                  tableName="activeUser"
                  // className="min-w-[99%] overflow-auto m-[.5%] w-[1400px] rounded-lg"
                  className="min-w-[99%] m-[.5%] rounded-lg overflow-hidden p-4"
                  tableHeader={mobileTableHeader}
                  tableBody={potInfoMobileTable}
                  pagination={pagination}
                  component={component}
                />

                {component === "history" ? (
                  <>
                    <div className="border border-1 mt-[5%] w-[95%] m-auto"></div>
                    <h1 className="ml-[5%] mt-[5%] text-[22px]">
                      History of Picks
                    </h1>
                    <Table
                      tableName="historyPicks"
                      className="min-w-[99%] m-[.5%] mt-[5%] rounded-lg overflow-hidden p-4"
                      tableHeader={mobilesecondTableHeader}
                      tableBody={historyMobileTable}
                      component="historyPicksMobile"
                    />
                  </>
                ) : null}
              </div>
            </Card>
          </div>
        </>
      )}
    </div>
  );
}

export default ActiveTeam;
