import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import modelX from "../../assets/images/model-x.svg";
import money from "../../assets/images/moneys.png";
import { confrimPopUp, newTokenBuy } from "../GreenDashboard/dashboard.slice";

import securityIcon from "../../assets/images/security-model.png";
import { buyTocken } from "../../services/dashboard/token";

const tokenValues = [
  {
    id: 1,
    value: 2,
  },
  {
    id: 2,
    value: 4,
  },

  {
    id: 3,
    value: 6,
  },
  {
    id: 4,
    value: 8,
  },
];

function CreateTokenModel() {
  const dispatch = useDispatch();

  const isBuyNewToken = useSelector((state) => state.dashboard.isBuyNewToken);

  const amountPerToken = useSelector(
    (state) => state.playerLayout.userTokenDetail.amount_per_token
  );

  const [isOpen, setIsOpen] = useState(isBuyNewToken);

  useEffect(() => {
    setIsOpen(isBuyNewToken);
  }, [isBuyNewToken]);

  const togglePopup = () => {
    dispatch(newTokenBuy(!isOpen));
    setIsOpen(!isOpen);
  };

  const [clickedButton, setClickedButton] = useState(1);
  const [tokenSelected, setTokenSelected] = useState(false);


  const selectTokenhandle = (token) => {
    setClickedButton(token);
  };

  const buyTockenHandler = () => {
    if (clickedButton > 0) {
      dispatch(
        confrimPopUp({ popup: true, selectedTokenToBuy: clickedButton })
      );
      // dispatch(buyTocken("/accounts/buytokens", { token: clickedButton }));
    }
  };

  let tokenAmount = Number(localStorage.getItem("gainAmount"));
  let tokenAmountCal = ((clickedButton * amountPerToken) / 100) * 2.75;
  tokenAmountCal = parseFloat(tokenAmountCal.toFixed(2));
  return (
    <div>
      {isOpen && (
        <div className="fixed z-60 inset-0 flex items-center justify-center z-20">
          <div className="absolute inset-0 bg-gray-800 opacity-75 p-6"></div>
          <div
            className="relative z-60 mobile-s:w-[90%] mobile:w-[95%] tablet:w-[574px]
            laptop-s:w-[55%] laptop-l:w-[35%]
            desktop:w-[25%] bg-white p-8 shadow-lg rounded-[8px]"
          >
            <button
              className="absolute top-0 p-4 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} />
            </button>
            <div className="flex flex-col items-center mt-5">
              <p className="font-semibold mobile-s:text-[18px] mobile-m:!text-[24px] text-center text">
                Pick number of tokens you want ?
              </p>
              <div className="flex flex-wrap tablet:flex-nowrap justify-center gap-5 mt-5">
                {tokenValues.map((token) => {
                  return (
                    <Button
                      className={`sm:w-1/2 text-[16px] tablet:w-[110px] rounded-[8px] mobile-s:w-[45%] py-3 px-2 font-bold focus:outline-none focus:shadow-outline mb-2 border border-gray-300 hover:border-blue-500 text-[#000000] ${
                        clickedButton === token?.value ? "bg-blue-200" : ""
                      }`}
                      type="submit"
                      onClick={() => {
                        setTokenSelected(true);
                        selectTokenhandle(token?.value);
                      }}
                    >
                      {token?.value} Token
                      <p className="text-[#00000080]">
                        ({token?.value * amountPerToken} AED)
                      </p>
                    </Button>
                  );
                })}
              </div>
              {}
              {tokenSelected && (
                <p className="mt-2">
                  Transaction fee of AED {tokenAmountCal} will be debited
                  additionally
                </p>
              )}
              <Button
                className={`bg-[#0AB159] ${
                  !tokenSelected && "opacity-50"
                } flex mobile-s:w-full items-center cursor-pointer justify-center hover:bg-[#0AB159] rounded-[8px] w-[60%] py-3 px-6 text-white font-bold focus:outline-none focus:shadow-outline mt-12 mb-4`}
                type="submit"
                onClick={buyTockenHandler}
                disabled={!tokenSelected ? true : false}
              >
                <span>Buy Tokens</span> <img src={money} className="ml-2" />
              </Button>
            </div>
            <div className="flex mobile-s:w-full items-center justify-center align-center gap-[2%]">
              <p className="text-[12px] ml-[2%]">
                Your security is more important to us
              </p>
              <img
                src={securityIcon}
                className="mobile-s:w-[48px] mobile-s:h-[17px]"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateTokenModel;
