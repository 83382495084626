import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Highcharts3D from "highcharts/highcharts-3d";
import useFindDevice from "../../CustomHooks/FindDevice";

Highcharts3D(Highcharts); // Initialize the 3D module

const PieChart = ({ predictionTeams }) => {
  const colors = JSON.parse(process.env.REACT_APP_COLORS);
  let { isMob } = useFindDevice();

  const filteredData = (predictionTeams?.top_5 || [])
    .filter((team) => team?.prediction_count > 0)
    .map((team, index) => ({
      name: team.prediction_count.toString(), // This is the upsell number
      y: team.prediction_count,
      color: colors[index],
      team_name: team.team_name, // Include team_name in the data point
    }));

  const options = {
    chart: {
      type: "pie",
      options3d: {
        enabled: true,
        alpha: 20,
        beta: 0,
      },
      backgroundColor: "rgba(0, 0, 0, 0.0)", // Reduced opacity background color
      height: isMob ? "180px" : "320px", // Adjust height based on device
      animation: false, // Disable initial animation
    },
    title: {
      text: null, // Remove chart title text
    },
    credits: {
      enabled: false, // Disable the Highcharts.com label
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.team_name}</b>: ${this.point.y}`;
      },
    },
    plotOptions: {
      pie: {
        size: "90%",
        innerSize: "50%",
        depth: 70,
        dataLabels: {
          connectorWidth: 0, // Remove the lines
          enabled: true,
          useHTML: true,
          distance: 3,
          verticalAlign: "top",
          y: 9,
          x: 0,
          formatter: function () {
            return `<div style="font-size: 14px; color: #1E1E1E;">${this.point.name}</div>`;
          },
        },
        animation: false, // Disable animation for pie slices
        point: {
          events: {
            mouseOver: function () {
              this.slice();
            },
            mouseOut: function () {
              this.slice();
            },
          },
        },
      },
    },
    series: [
      {
        name: "Predictions",
        colorByPoint: true,
        data: filteredData,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
