import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import menuIcon from "../../assets/images/textalign-left.png";
import { fetchUserSuccess } from "../Login/login.slice";

import sitLogo from "../../assets/images/site-logo.png";
import menuArrowImg from "../../assets/images/menu-arrow-img.png";
import dashImg from "../../assets/images/dashboard-bg.svg";

import vectorArrow from "../../assets/card/vector-line.svg";
import addToken from "../../assets/card/add-token.svg";
import tokenBuget from "../../assets/card/token-buget.svg";

import textalignLeft from "../../assets/card/textalign-left.svg";
import "./layout.css";
import {
  initialToken,
  newTokenBuy,
  popupClose,
  resetTab,
} from "../GreenDashboard/dashboard.slice";
import CreateTokenModel from "../Models/CreateTokenModel";
import ConfrimPopUp from "../Models/ConfrimPopUp";
import { fetchUserDetail } from "../../services/backenApiCall";

import logoPicknPrizes from "../../assets/card/logo-pick-n-prizes.svg";
import Loading from "../Models/loader";

function PlayerLayout(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userName: "",
    email: "",
    availableToken: 0,
  });
  let [mobileUserName, setMobileUserName] = useState("");

  const userPot = useSelector((state) => state.dashboard);

  const [userDetail, setUserDetail] = useState({ availToken: 0 });

  // dispatch(popupClose({isPaymentLoading:false}))
  useEffect(() => {
    if (userPot?.data?.user && Object.keys(userPot.data.user).length > 0) {
      setUserDetail({
        availToken: userPot.data.user.available_tokens,
      });
      setUserInfo((prev) => ({
        userName: userPot?.data?.user?.username,
        email: userPot?.data?.user?.email,
      }));
    }
  }, [userPot]);

  let tokenInfo = JSON.parse(localStorage.getItem("userProfileInfo"));

  useEffect(() => {
    let localUserInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (localUserInfo && tokenInfo) {
      setUserInfo((prev) => ({
        ...prev,
        userName: tokenInfo.userName,
        email: localUserInfo.email,
        availableToken: tokenInfo.initialToken,
      }));
    }
  }, []);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  useEffect(() => {
    const handleClick = (event) => {
      // Close dropdown if clicking outside of it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);


  const logoutHandler = () => {
    try {
      dispatch(resetTab());
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthUser");
      localStorage.removeItem("isAdminUser");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("dashboardTab");
      localStorage.removeItem("userProfileInfo");
      dispatch(fetchUserSuccess({}));
      navigate("/home");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileResolution = window.innerWidth <= 765; // Adjust the threshold as needed
      setIsMobile(isMobileResolution);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyles = {
    backgroundImage: `url(${dashImg})`,
  };

  const logoHandler = () => {
    navigate("/dashboard");
  };

  const createToken = () => {
    dispatch(newTokenBuy(true));
  };

  useEffect(() => {
    if (userInfo.userName.length > 1) {
      setMobileUserName(userInfo.userName);
    } else {
      setMobileUserName(userInfo.userName);
    }
  }, [userInfo?.userName]);

  const [picks, setPicks] = useState({
    isBuyNewToken: false,
    isConfirmPopUp: false,
  });

  const selectedPick = useSelector((state) => {
    return {
      buyNewToken: state.dashboard.isBuyNewToken,
      confirmPopUp: state.dashboard.confirmPopUp,
    };
  });
  useEffect(() => {
    setPicks((prev) => ({
      ...prev,
      isBuyNewToken: selectedPick.buyNewToken,
      isConfirmPopUp: selectedPick.confirmPopUp,
    }));
  }, [selectedPick.buyNewToken, selectedPick.confirmPopUp]);

  const [playerInfo, setPlayerInfo] = useState({
    userToken: 0,
    userName: "",
    buyToken: 0,
    winningToken: 0,
  });
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchUserDetail("/accounts/user/me"));
  }, []);

  const playerDetails = useSelector((state) => {
    return state.playerLayout.userTokenDetail;
  });

  useEffect(() => {
    if (Object.keys(playerDetails).length > 0) {
      setPlayerInfo((prev) => ({
        ...prev,
        userToken: playerDetails.available_tokens,
        buyToken: playerDetails.available_tokens,
        winningToken: playerDetails.won_tokens,
      }));
    }
  }, [playerDetails]);

  function spliUserName() {
    if (userInfo?.userName) {
      let truncatedUsername = userInfo.userName.slice(0, 10);
      return truncatedUsername;
    }
  }

  return (
    // <div className="overflow-auto font-interFont bg-[url('../public/card/blue-bg-img.svg')] bg-cover h-screen">
    <div
      className={` bg-cover min-h-[100dvh] overflow-auto font-interFont bg-[url('../public/card/dark-green-bg.svg')] bg-[#0c2310]`}
    >
      {picks?.isBuyNewToken ? <CreateTokenModel /> : null}
      {picks?.isConfirmPopUp ? <ConfrimPopUp /> : null}
      <header
        className={`text-white header-player h-[60px] laptop-s:mt-0 laptop-l:mt-0 desktop:mt-[10px] tablet:mt-0 mt-[15px] laptop-min:py-1 ${
          isMobile ? "p-3 pt-0 pb-10" : "py-1 w-[96%] m-auto"
        }`}
      >
        <nav className="flex items-center justify-between">
          {isMobile ? (
            <div
              className="relative flex flex-1 items-center  z-40"
              id="userDropdown"
            >
              <div className="flex w-[100%] justify-between gap-2 items-center">
                <div className="">
                  <img
                    src={logoPicknPrizes}
                    className={`mobile-s:w-[88px] mobile-s:h-[38px] mobile-m:!w-[100px] mobile-m:!h-[50px] cursor-pointer`}
                    alt="Logo"
                    onClick={logoHandler}
                  />
                </div>
                <div className="flex w-[70%] justify-end gap-2">
                  <div className="flex justify-center px-2 rounded-[6px] gap-1 bg-[#FFFFFF1A] items-center">
                    <img
                      src={tokenBuget}
                      className="mobile-s:h-[16px] mobile-s:w-[16px] mobile-m:!h-[22px] mobile-m:!w-[22px]"
                    />
                    <div className="flex items-center gap-1">
                      <p className="font-bebasNeue font-weigth-[700] mobile-s:text-[14px] text-[700] mobile-m:!text-[22px] text-[#FFFFFF]">
                        {playerInfo.buyToken + playerInfo.winningToken}
                      </p>
                      <span className="font-lato text-[12px] text-[500]">
                        Tokens
                      </span>
                    </div>
                    <img
                      src={addToken}
                      className="mobile-s:h-[14px] mobile-s:w-[14px] mobile-m:!h-[18px] mobile-m:!w-[18px]"
                      onClick={createToken}
                    />
                  </div>
                  <div className="flex bg-[#FFFFFF1A] items-center justify-between p-1 rounded-[6px] px-1">
                    <p className="mobile-s:text-[12px] text-nowrap mobile-m:text-[14px] font-lato text-[500] text-[#FFFFFF]">
                      {spliUserName()}
                    </p>
                    <img
                      src={textalignLeft}
                      alt="User"
                      className="w-10 h-10 mr-2 cursor-pointer"
                      onClick={toggleDropdown}
                    />
                  </div>
                </div>
              </div>
              {isDropdownVisible && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-2 bg-white border rounded-md p-2 z-50"
                  style={{ top: "100%", marginTop: "4px", zIndex: 11 }}
                >
                  <div
                    className="flex items-center my-pick rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }} // Initially transparent
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={handImg} className="h-5 w-5" /> */}
                    <Link
                      to="/myPicks"
                      type="button"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      My Picks
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                    />
                  </div>
                  <div
                    className="flex items-center my-purchases rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }} // Initially transparent
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={shopping} className="h-5 w-5" /> */}
                    <Link
                      to="/myPurchases"
                      type="button"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      My Purchases
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[10px]"
                    />
                  </div>
                  <div
                    className="flex items-center my-winning rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }} // Initially transparent
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={winImg} className="h-5 w-5" /> */}
                    <Link
                      to="/myWinnings"
                      type="button"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      My Winnings
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[10px]"
                    />
                  </div>
                  <div
                    className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={roles} className="h-5 w-5" /> */}
                    <Link
                      to="/withdraw"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      Withdraw
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                    />
                  </div>
                  <div
                    className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={updateProfile} className="h-5 w-5" /> */}
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      My Profile
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                    />
                  </div>

                  <div
                    className="flex items-center my-purchases rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }} // Initially transparent
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={shopping} className="h-5 w-5" /> */}
                    <Link
                      to="/home"
                      type="button"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      How To Play
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[10px]"
                    />
                  </div>
                  <div
                    className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                    onClick={() => {
                      navigate("/contactUs");
                    }}
                  >
                    {/* <img src={roles} className="h-5 w-5" /> */}
                    <Link
                      to="/contactUs"
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      Help
                    </Link>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                      onClick={() => {
                        navigate("/contactUs");
                      }}
                    />
                  </div>
                  <div
                    className="flex items-center logout rounded-l-[6px] rounded-r-[6px]"
                    style={{ border: "1px solid transparent" }} // Initially transparent
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = "1px solid green";
                      e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = "1px solid transparent";
                      e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                    }}
                  >
                    {/* <img src={logout} className="ml-1 h-5 w-5" /> */}
                    <button
                      type="button"
                      onClick={logoutHandler}
                      className="block px-4 py-2 text-[#0000007A] text-[15px]"
                    >
                      Log Out
                    </button>
                    <img
                      src={menuArrowImg}
                      className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="flex items-center">
                <img
                  src={sitLogo}
                  className="w-[153px] h-[66px] ml-4 cursor-pointer"
                  alt="Logo"
                  onClick={logoHandler}
                />
              </div>
              <div className="flex items-center">
                <div className="flex bg-[#FFFFFF1A] p-2 rounded-[6px]">
                  <div className="flex items-center gap-2 mr-2">
                    <img src={tokenBuget} className="h-6 w-6" />
                    <div className="flex items-center gap-1">
                      <p className="font-bebasNeue font-weigth-[700] text-[22px] text-[#FFFFFF]">
                        {playerInfo.buyToken + playerInfo.winningToken}
                      </p>
                      <span className="font-lato text-[14px]">Tokens</span>
                    </div>
                  </div>
                  <div className="flex mt-1">
                    <img
                      src={addToken}
                      className="h-6 w-6 cursor-pointer"
                      onClick={createToken}
                    />
                  </div>
                </div>
                <div className="mx-8">
                  {" "}
                  <img src={vectorArrow} className="h-14" />
                </div>
                <div className="relative flex items-center" id="userDropdown">
                  <div className="flex items-center">
                    {/* <img
                    src={userImg}
                    alt="User"
                    className="rounded-full w-14 h-10 cursor-pointer"
                  /> */}
                    <div className="ml-2">
                      <p className="text-sm font-semibold text-[15px] text-[#FFFFFF]">
                        {userInfo?.userName}
                      </p>
                      <p className="text-xs text-[12px] text-[#F1F5F9B27A]">
                        {userInfo.email}
                      </p>
                    </div>
                  </div>

                  {/* <img
                  src={menuIcon}
                  alt="User"
                  className="w-14 h-10 mr-2 cursor-pointer"
                  onClick={toggleDropdown}
                /> */}
                  <img
                    src={menuIcon}
                    className="w-[33px] h-[33px] mr-2 ml-5 cursor-pointer color-[#FFFFFF]"
                    onClick={toggleDropdown}
                    alt="Logo"
                  />

                  {isDropdownVisible && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-0 mt-2 bg-white border rounded-md p-2 z-50"
                      style={{ top: "100%", marginTop: "4px" }}
                    >
                      <div
                        className="flex items-center my-pick rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }} // Initially transparent
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={handImg} className="h-5 w-5" /> */}
                        <Link
                          to="/myPicks"
                          type="button"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          My Picks
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-purchases rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }} // Initially transparent
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={shopping} className="h-5 w-5" /> */}
                        <Link
                          to="/myPurchases"
                          type="button"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          My Purchases
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[10px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-winning rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }} // Initially transparent
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={winImg} className="h-5 w-5" /> */}
                        <Link
                          to="/myWinnings"
                          type="button"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          My Winnings
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[10px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-pick rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }} // Initially transparent
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={handImg} className="h-5 w-5" /> */}
                        <Link
                          to="/withdraw"
                          type="button"
                          className="block px-4 py-2 text-[#0000007A] text-[15  px]"
                        >
                          Withdraw
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={updateProfile} className="h-5 w-5" /> */}
                        <Link
                          to="/profile"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          My Profile
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent";
                        }}
                      >
                        <Link
                          to="/home"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          How To Play
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[20px]"
                        />
                      </div>
                      <div
                        className="flex items-center my-profiles rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent";
                        }}
                        onClick={() => {
                          navigate("/contactUs");
                        }}
                      >
                        <Link
                          to="/contactUs"
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          Help
                        </Link>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[20px]"
                          onClick={() => {
                            navigate("/contactUs");
                          }}
                        />
                      </div>
                      <div
                        className="flex items-center logout rounded-l-[6px] rounded-r-[6px]"
                        style={{ border: "1px solid transparent" }} // Initially transparent
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "1px solid green";
                          e.currentTarget.style.backgroundColor = "#EDFFF5"; // Background color on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border =
                            "1px solid transparent";
                          e.currentTarget.style.backgroundColor = "transparent"; // Reset background color
                        }}
                      >
                        {/* <img src={logout} className="ml-1 h-5 w-5"/> */}
                        <button
                          type="button"
                          onClick={logoutHandler}
                          className="block px-4 py-2 text-[#0000007A] text-[15px]"
                        >
                          Log Out
                        </button>
                        <img
                          src={menuArrowImg}
                          className="hidden-on-hover h-3 w-3 mt-1 mr-[30px]"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </nav>
      </header>
      <div className="">
        <Loading />
        {props?.children}
      </div>
    </div>
  );
}

export default PlayerLayout;
