import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PlayerLayout from "../PlayerLayout/PlayerLayout";

import ActiveTeam from "../ViewDetails/ActiveTeam/ActiveTeam";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyPickData } from "../../services/myPicks/myPicks";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";
import { myTableHeader } from "../TableHeader/TableHeader";

import greenDat from "../../assets/card/green-dat.svg";



function MyPicks({ isMobile, isLaptop }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [myPick, setMyPick] = useState([]);
  const [mobileMyPick, setMobileMyPick] = useState([]);

  const [pagination, setPagination] = useState({});
  const paginationData = useSelector((state) => state.myPicks.pagination);

  const myPickInfo = useSelector((state) => state.myPicks.myPicksInfo);
  const myPickMobile = useSelector((state) => state.myPicks.myPicksMobils);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalRecord: paginationData?.totalRecord,
      pageSize: paginationData?.pageSize,
      currentPage: paginationData?.currentPage,
    }));
  }, [
    paginationData.totalRecord,
    paginationData.currentPage,
    paginationData.pageSize,
  ]);

  useEffect(() => {
    const player = JSON.parse(localStorage.getItem("userInfo"));
    if (player?.userId && pagination?.currentPage) {
      dispatch(
        fetchMyPickData(
          `/pot/playerpots?player=${player?.userId}&limit=10&page=${pagination?.currentPage}`
        )
      );
    }
  }, [pagination.currentPage]);

  const handleClick = (potId, pick, status) => {
    navigate(`/myHistories/${potId}/${pick}/${status}`);
  };

  useEffect(() => {
    let res;
    if (myPickInfo.length > 0) {
      res = myPicks(myPickInfo);
      setMyPick(res);
    }
    if (myPickMobile.length > 0) {
      res = myPicks(myPickMobile);
      setMobileMyPick(res);
    }
  }, [myPickInfo, myPickMobile]);

  function myPicks(myPickInfo) {
    let res = myPickInfo.map((pick) => {
      let obj = {
        pot_id: pick?.pot_id,
        pick_id: pick?.pick_id,
        leaguename: pick?.leaguename,
        last_mw: pick?.last_match_week,
        current_pot_value: formatAsIndianCurrency(
          pick?.current_pot_value,
          pick?.pot_currency
        ),
        status:
          pick.status === "Active" || pick.status === "Ongoing" ? (
            <div className="flex items-center gap-1 justify-center laptop-l:!w-[180px] tablet-l:!w-[150px] h-[30px] bg-[#00A34D0F] px-4 rounded-[5px] text-[14px] ">
              <img src={greenDat} className="h-3 w-3" />
              <p className="font-interFont text-[#00A34D]">{pick.status}</p>
            </div>
          ) : (
            <p>{pick.status}</p>
          ),
        no_of_picks: pick?.no_of_picks,
        pick_status: pick?.pick_status,
        action: (
          <Button
            className="bg-[#0AB159] cursor-pointer laptop-s:!w-[170px] laptop-l:!w-[120px] tablet:!text-[12px] tablet:!px-2 text-[14px] hover:bg-[#0AB159] px-[3%]
            py-3  text-white font-bold focus:outline-none focus:shadow-outline mx-[1%] mt-[3%] rounded-[10px] text-nowrap"
            type="button"
            onClick={() =>
              handleClick(pick?.pot_id, pick?.pick_id, pick?.pick_status)
            }
          >
            View Picks
          </Button>
        ),
      };
      if (isMobile) {
        obj.potCurrency = pick.pot_currency; // Assigns the 'pot_currency' value to 'potCurrency'
      }
      return obj;
    });
    return res;
  }

  return (
    <PlayerLayout>
      <div>
        <ActiveTeam
          myPickInfo={myPickInfo}
          isMobile={isMobile}
          isLabtop={isLaptop}
          tableHeader={myTableHeader}
          tableBody={myPick}
          pagination={pagination}
          mobileMyPick={mobileMyPick}
          component="myPicksMenu"
          staticMessage="You haven't made any picks yet. Join the game to make your first pick!"
        />
      </div>
    </PlayerLayout>
  );
}

export default TeamDetailHOC(MyPicks);
