import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyPicks,
  fetchPickStatus,
} from "../../../../../services/myPotView/myPotView";
import { utcTimetoLocalTimeWithzone } from "../../../../../utils/convertTime";

import newDashCardTri from "../../../../../assets/images/new-dah-card-tri-1.svg";
import newCardArrowRed from "../../../../../assets/images/down-arrow-img-red.svg";
import teamSelectedIcon from "../../../../../assets/images/team-selected-icon.svg";
import ChangeTeamPopUp from "../../../../Models/ChangeTeamPopUp";
import {
  confirmPopUpChange,
  updateSelectMatch,
} from "../../../../MyPotViewDetail/myPot.slice";
import { checkMatchWeekTime } from "../../../../../utils/compareMatchWeekTime";
import { RotatingLines } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

function Players({ isMobile, deadLine }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector((state) => state.myPot.loading);
  const [curentWeek, setCurrentWeek] = useState("");
  const [playerMatchData, setPlayerMatchData] = useState([]);

  const [currentMatchWeekNumber, setCurrentMatchWeekNumber] = useState([]);

  const [checkIsFutre, setCheckIsFutre] = useState(false);
  const [currentPickStatus, setCurrentPickStatus] = useState("");

  const [cMatchWeekNum, setCurrentMatchWeek] = useState();

  // const [, , , , potId, pickId, seasonId, matchWeek, potType] =
  //   window.location.href.split("/");
  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId: matchWeek, potType } = data;

  const isConfirmPopUp = useSelector((state) => {
    return state.myPot.confirmPopUp;
  });

  const [isChangeTeam, setIsChangeTeam] = useState(isConfirmPopUp);

  const selectedWeekNum = useSelector((state) => {
    return state.myPot.currentMatchWeek;
  });

  const playerMatchWeekData = useSelector((state) => state.myPot.playerPicks);

  const findChosePick = (playerMatchData) => {
    let isPredicted = false;
    structuredClone(playerMatchData).forEach((player) => {
      if (player.short.some((short) => short?.predicted === true)) {
        isPredicted = true;
      }
    });
    return isPredicted ? "edit" : "create";
  };

  const changeSelectedTeam = (id, teamType) => {
    if (currentMatchWeekNumber == curentWeek) {
      dispatch(confirmPopUpChange(true));
      const userAction = findChosePick(playerMatchData);
      const changePlayerMatchData = structuredClone(playerMatchData).find(
        (ele) => ele.id === id
      );
      let team = teamType === "home" ? "hometeam" : "awayteam";
      dispatch(
        updateSelectMatch({
          pot: potId,
          pickId: pickId,
          matchId: id,
          prediction: changePlayerMatchData[team],
          team: teamType,
          userAction: userAction,
        })
      );
    }
  };

  const matchWeekInfo = useSelector((state) => state.myPot.matchWeeks);

  useEffect(() => {
    if (matchWeekInfo.length > 0) {
      let curentMatchWeek = matchWeekInfo.find(
        (ele) => ele.current_matchweek === true
      );
      setCurrentMatchWeek(curentMatchWeek.id);
      let isFuture = checkMatchWeekTime(curentMatchWeek.start_time, deadLine);
      setCheckIsFutre(isFuture);
    }
  }, [matchWeekInfo]);

  useEffect(() => {
    setIsChangeTeam(isConfirmPopUp);
  }, [isConfirmPopUp]);

  useEffect(() => {
    setCurrentWeek(selectedWeekNum);
  }, [selectedWeekNum]);

  useEffect(() => {
    setCurrentMatchWeekNumber(cMatchWeekNum);
  }, [cMatchWeekNum]);

  useEffect(() => {
    if (potType === "newPot") {
      if (curentWeek && seasonId) {
        dispatch(
          fetchMyPicks(
            `/pot/fixtures?matchweek=${curentWeek}&league=${seasonId}`
          )
        );
      }
    } else {
      if (curentWeek && pickId) {
        dispatch(
          fetchMyPicks(`/pot/pickmatchpredictions/${pickId}/${curentWeek}`)
        );
      }
    }
  }, [curentWeek, pickId, potType]);

  useEffect(() => {
    const pickID = Number(pickId);
    if (pickID) {
      getPickStatus(pickID);
    }
  }, [pickId]);

  async function getPickStatus(pickID) {
    const res = await dispatch(fetchPickStatus(`/pot/potplayer/${pickID}`));
    if (res?.status) {
      setCurrentPickStatus(res.status);
    }
  }

  useEffect(() => {
    if (playerMatchWeekData.length > 0) {
      setPlayerMatchData(playerMatchWeekData);
    }
  }, [playerMatchWeekData]);

  const handleLeftClick = (id) => {
    changeSelectedTeam(id, "home");
  };

  const handleRightClick = (id) => {
    changeSelectedTeam(id, "away");
  };

  return (
    <div style={{ position: "relative" }}>
      {" "}
      {isChangeTeam && checkIsFutre && currentPickStatus === "Active" && (
        <ChangeTeamPopUp />
      )}
      {isLoading ? (
        <div className="flex justify-center my-auto">
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="mt-[1%]">
          {playerMatchData?.length > 0 ? (
            <>
              {" "}
              {playerMatchData?.length > 0 &&
                playerMatchData.map(
                  ({
                    id,
                    hometeam,
                    awayteam,
                    start,
                    hometeam_logo,
                    awayteam_logo,
                    awayteam_goal,
                    hometeam_goal,
                    short,
                    result,
                  }) => {
                    const date = utcTimetoLocalTimeWithzone(start).split(" ");
                    const time = `${date[3].split(":")[0]}:${
                      date[3].split(":")[1]
                    }`;
                    let concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
                    let mobileDate = `${date[1]} ${date[2]}`;

                    let homePredicted = short.find(
                      (ele) => ele.team_type === "home"
                    );
                    let awayPredicted = short.find(
                      (ele) => ele.team_type === "away"
                    );
                    return (
                      <div
                        className={`flex mobile-s:w-full items-center justify-between`}
                        key={id}
                      >
                        <div
                          className={`flex gap-5 left-side mobile-s:w-[50%] laptop-s:!w-[30%] p-[1%] overflow-auto`}
                          onClick={() => {
                            if (!homePredicted?.not_available) {
                              handleLeftClick(id);
                            }
                          }}
                          title={
                            homePredicted?.not_available
                              ? "This team already selected."
                              : ""
                          }
                        >
                          <div
                            className={`flex justify-between left-side mobile-s:p-[5%] mobile-s:px-[1px] tablet:p-[4%] laptop-l:!p-[2%] overflow-hidden ${
                              homePredicted?.predicted &&
                              "border-blue-500 border-[1px] bg-[#0000001A]"
                            }  rounded-[5px] 
                    transition-all duration-300 ease-in-out`}
                            style={{ flex: "25%" }}
                            // onClick={changeTeam(homePredicted?.predicted)}
                          >
                            <div className="flex gap-2 w-[30%] items-center">
                              <img
                                src={hometeam_logo}
                                alt="Team Image"
                                className={`mobile-s:h-6 mobile-s:w-6 mobile-m:w-8 mobile-m:h-8 ${
                                  homePredicted?.not_available
                                    ? "opacity-50"
                                    : ""
                                }`}
                              />
                              <p
                                className={`text-left mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px]
                     mobile-l:text-[13px] text-nowrap ${
                      homePredicted?.not_available ? "opacity-50" : ""
                     }`}
                              >
                                {short[0]?.team_type === "home"
                                  ? short[0]?.code
                                  : short[1]?.code}
                              </p>
                            </div>
                            {homePredicted?.predicted && (
                              <img
                                src={teamSelectedIcon}
                                className="ml-[3%] mt-[1%] w-[20px] h-[20px]"
                                alt="Selected"
                              />
                            )}
                          </div>
                          <div className="flex px-2">
                            {hometeam_goal === "None" &&
                            awayteam_goal === "None" ? null : (
                              <>
                                {result === hometeam ? (
                                  <div className="flex gap-2 items-center">
                                    {short[0]?.team_type === "home" ? (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[0]?.prediction_count}
                                      </p>
                                    ) : (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[1]?.prediction_count}
                                      </p>
                                    )}

                                    <img
                                      src={newDashCardTri}
                                      className="h-3 w-3"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex gap-2 items-center">
                                    {short[0]?.team_type === "home" ? (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[0]?.prediction_count}
                                      </p>
                                    ) : (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[1]?.prediction_count}
                                      </p>
                                    )}
                                    <img
                                      src={newCardArrowRed}
                                      className="h-3 w-3"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="flex justify-center p-2 items-center ">
                          <div
                            className="flex p-2 tablet:w-[200px] mobile-l:w-[100px] mobile-s:w-[50px] mobile-m:w-[100px]
                  laptop-s:w-[130px] laptop-l:w-[130px] 
                  bg-[#9491911a] justify-center items-center rounded-[4px]"
                          >
                            {awayteam_goal === "None" ? (
                              <p className="flex text-[12px] justify-center text-nowrap">
                                {hometeam_goal === "None"
                                  ? isMobile
                                    ? mobileDate
                                    : concatDate
                                  : 0}
                              </p>
                            ) : (
                              <p className="flex items-center justify-center text-[10px]">
                                {" "}
                                {hometeam_goal} | {awayteam_goal}
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className={`left-side gap-5 mobile-s:w-[50%] laptop-s:!w-[30%] overflow-auto flex justify-end`}
                          onClick={() => {
                            if (!awayPredicted?.not_available) {
                              handleRightClick(id);
                            }
                          }}
                          title={
                            awayPredicted?.not_available
                              ? "This team already selected."
                              : ""
                          }
                        >
                          <div className="flex gap-5 items-center justify-normal w-[15%]">
                            {hometeam_goal === "None" &&
                            awayteam_goal === "None" ? null : (
                              <>
                                {result === awayteam ? (
                                  <div className="flex gap-2 items-center">
                                    {short[0]?.team_type === "away" ? (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[0]?.prediction_count}
                                      </p>
                                    ) : (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[1]?.prediction_count}
                                      </p>
                                    )}
                                    <img
                                      src={newDashCardTri}
                                      className="h-3 w-3"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex gap-2 mr-2 items-center">
                                    {short[0]?.team_type === "away" ? (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[0]?.prediction_count}
                                      </p>
                                    ) : (
                                      <p className="mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap">
                                        {short[1]?.prediction_count}
                                      </p>
                                    )}
                                    <img
                                      src={newCardArrowRed}
                                      className="h-3 w-3"
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          <div
                            className={`flex mobile-s:pr-[12%] gap-5 mobile-s:p-[5%] ${
                              awayPredicted?.predicted
                                ? "justify-between"
                                : "justify-end"
                            } mobile-s:px-[1px] tablet:p-[4%] laptop-l:!p-[3%] laptop-l:!px-[5%]  overflow-hidden ${
                              awayPredicted?.predicted &&
                              "border-blue-500 border-[1px] bg-[#0000001A]"
                            } rounded-[5px] transition-all duration-300 ease-in-out`}
                            style={{ flex: "25%" }}
                          >
                            {awayPredicted?.predicted && (
                              <img
                                src={teamSelectedIcon}
                                className="ml-[3%] mt-[1%] w-[20px] h-[20px]"
                                alt="Selected"
                              />
                            )}
                            <div
                              className="flex gap-2 
                          items-center justify-center"
                            >
                              <div
                                className="flex gap-2 
                          items-center justify-center w-[40%]"
                              >
                                <p
                                  className={`text-right mobile-s:text-[10px] mobile-m:!text-[12px] laptop-l:!text-[15px] items-center mobile-l:[13px] text-nowrap ${
                                    awayPredicted?.not_available
                                      ? "opacity-50"
                                      : ""
                                  }`}
                                >
                                  {short[1]?.team_type === "away"
                                    ? short[1]?.code
                                    : short[0]?.code}
                                </p>
                                <img
                                  src={awayteam_logo}
                                  alt="Team Image"
                                  className={`mobile-s:h-6 mobile-s:w-6 mobile-m:w-8 mobile-m:h-8 ${
                                    awayPredicted?.not_available
                                      ? "opacity-50"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </>
          ) : (
            <p className="flex justify-center">No Fixtures there.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Players;
