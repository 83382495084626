import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import Card from "../../Elements/Card";
import AuthLayout from "../AuthLayout/AuthLayout";
import Button from "../../Elements/Button";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { resendOTPCode, verifyOTPCode } from "../../services/auth/verification";
import { otpValueChange } from "./verification.slice";

function Verification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [resent, setResent] = useState(true);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [isServerErrorMsg, setServerIsErrorMsg] = useState(false);
  const [resendOtpSucc, setResendOtpSucc] = useState(false); // resend otp success message

  const [errorMsg, setErrorMsg] = useState("Please Enter OTP");
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [serverError, setServerError] = useState(""); // error message


  useEffect(() => {
    const storedEndTime = localStorage.getItem("countdownEndTime");
    if (storedEndTime) {
      const remainingTime = new Date(storedEndTime).getTime() - Date.now();
      if (remainingTime > 0) {
        setCountdownTime(new Date(storedEndTime).getTime());
        setResent(true);
      } else {
        setResent(false);
      }
    } else {
      const newEndTime = Date.now() + 300000; // 5 minutes from now
      localStorage.setItem(
        "countdownEndTime",
        new Date(newEndTime).toISOString()
      );
      setCountdownTime(newEndTime);
      setResent(true);
    }
  }, []);

  const [countdownTime, setCountdownTime] = useState(Date.now() + 300000); // Initial countdown time

  const handleChange = (enteredOtp) => {
    setIsErrorMsg(false);
    setServerError("");

    // Check if enteredOtp consists only of numeric characters
    if (/^\d+$/.test(enteredOtp) || enteredOtp === "") {
      setOtp(enteredOtp);
      if (enteredOtp.length === 4) {
        setVerifyBtn(true);
      } else {
        setVerifyBtn(false);
      }
    }
  };

  const OtpCounter = ({ minutes, seconds, completed }) => {
    if (completed) {
      setResent(false);
      return null;
    } else {
      return (
        <span>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      );
    }
  };

  const renderInput = (inputProps, index) => {
    let mx = "";
    if (index > -1) {
      mx = "mx-[5%]";
    } else if (index === 1) {
      mx = "mx-[15%]";
    } else {
      mx = null;
    }
    
    const handleInputChange = (event, currentIndex) => {
      const { value } = event.target;
      
      // Allow only numeric input
      const newValue = value.replace(/\D/g, "");
      
      // Update OTP state
      setOtp((prevOtp) => {
        const newOtp =
          prevOtp.slice(0, currentIndex) +
          newValue.slice(-1) +
          prevOtp.slice(currentIndex + 1);
        return newOtp;
      });
      
      // Move focus to the next input if it's a single digit and not the last input
      if (newValue.length === 1 && currentIndex < 3) {
        const nextIndex = currentIndex + 1;
        const nextInput = document.getElementById(`otp-input-${nextIndex}`);
        if (nextInput) {
          nextInput.focus();
          nextInput.select(); // Optionally select the text in the input for better UX
        }
      }
    };
    
    return (
      <input
      {...inputProps}
      maxLength={1}
      id={`otp-input-${index}`}
      className={`otp-input w-12 h-12 py-2 border border-gray-300 rounded text-center focus:outline-none focus:border-blue-500 ${mx}`}
      style={{
        width: "40px",
        height: "40px",
        border: "1px solid black", // Uncomment this line to apply the border
      }}
      key={index}
      autoFocus={index === 0}
      onChange={(e) => handleInputChange(e, index)}
    />
    );
  };

  const verifyEmail = JSON.parse(localStorage.getItem("registerEmail"));
  let pageName = window.location.pathname.split("/")[2];

  const handleResend = async () => {
    setOtp("");
    const newEndTime = Date.now() + 300000;
    localStorage.setItem(
      "countdownEndTime",
      new Date(newEndTime).toISOString()
    );
    setCountdownTime(newEndTime);
    setVerifyBtn(true);
    setServerIsErrorMsg(false);
    setResent(true);
    let res = await dispatch(
      resendOTPCode("/accounts/resendotp", { email: verifyEmail })
    );
    if (res?.status === "Success") {
      setResendOtpSucc(true);
    } else {
      setResendOtpSucc(false);
    }
  };

  const submitHandler = async (e) => {
    debugger
    e.preventDefault();
    setServerIsErrorMsg(false);
    if (otp.length === 4) {
      setIsErrorMsg(false);
      if (verifyEmail && otp) {
        const res = await dispatch(
          verifyOTPCode("/accounts/activate", { otp, email: verifyEmail })
        );

        if (res?.status === "Success") {
          localStorage.removeItem("registerEmail");
          navigate("/login");
        } else {
          setOtp("");
          setServerIsErrorMsg(true);
          setServerError("OTP Invalid");
        }
      } else {
        setIsErrorMsg(true);
      }
    } else {
      setIsErrorMsg(true);
    }
  };

  return (
    <AuthLayout>
      <Card className="bg-[#FFFFFF] flex flex-col m-auto shadow-md font-[inter] rounded-[12px] px-16 py-12 tablet:w-[70%] tablet:mx-auto tablet:my-auto laptop-s:w-[55%] laptop-l:w-[30%]  justify-center mobile-s:w-[90%] mobile-s:text-[16px]">
        {resendOtpSucc && (
          <p className="text-green-500 text-center">
            Resend OTP Sent to Your Email
          </p>
        )}
        <form onSubmit={submitHandler}>
          <div className="m-auto">
            <h2 className="text-2xl font-semibold mb-3 mobile-s:text-lg">
              Verify your email
            </h2>
            <p className="text-sm text-gray-600 mb-4 mobile-s:mb-2">
              Please enter the code that we sent to{" "}
              <span className="text-black">{verifyEmail}</span>
            </p>
            <div className="flex items-center space-x-2 mb-4">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                separator={<span>-</span>}
                renderInput={renderInput}
              />
            </div>
            {serverError && <p className="text-red-500">{serverError}</p>}
            {isErrorMsg && <p className="text-red-500">{errorMsg}</p>}

            <div className="mt-1">
              <p className="text-[#000000]">
                Didn’t receive code? <span>Check your spam or</span>{" "}
                {!resent ? (
                  <a
                    className="text-blue-500 underline focus:outline-none cursor-pointer"
                    onClick={handleResend}
                  >
                    Resend code
                  </a>
                ) : null}
                {resent ? (
                  <Countdown
                    key={countdownTime}
                    date={countdownTime}
                    renderer={OtpCounter}
                  />
                ) : null}
              </p>
            </div>

            <Button
              type="submit"
              disabled={otp.length === 0}
              className={`px-8 py-3 text-lg font-bold rounded focus:outline-none focus:shadow-outline mt-6 mb-4 mobile-s:my-2 mobile-s:text-sm ${
                otp.length === 0
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700 text-white"
              }`}
            >
              Verify Code
            </Button>
          </div>
        </form>
      </Card>
    </AuthLayout>
  );
}

export default Verification;
