import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedCurrentMatchWeek } from "../../../../MyPotViewDetail/myPot.slice";
import "./style.css";
import { useLocation } from "react-router-dom";

import leftArrowButton from "../../../../../assets/card/new-left-arrow.svg";
import rigthArrowButton from "../../../../../assets/card/new-rigth-arrow.svg";

function Matchweek({ isMobile }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const data = location.state;
  const { matchWeekId: mk, potType } = data;

  const [matchWeek, setMatchWeek] = useState([]);

  const matchWeekInfo = useSelector((state) => state.myPot.matchWeeks);
  const [currentMatchWeek, setCurrentMatchWeek] = useState(1); // default to 1

  useEffect(() => {
    if (matchWeekInfo?.length > 0) {
      setMatchWeek(matchWeekInfo);
    }
  }, [matchWeekInfo]);

  useEffect(() => {
    const currentWeek = matchWeek.find(
      (week) => week.current_matchweek === true
    );
    if (currentWeek) {
      setCurrentMatchWeek(currentWeek.matchweek_number);
      dispatch(selectedCurrentMatchWeek(currentWeek.id));
    }
  }, [matchWeek]);

  const handleWeekChange = (week) => {
    setCurrentMatchWeek(week);
    dispatch(
      selectedCurrentMatchWeek(
        matchWeek.find((w) => w?.matchweek_number === week)?.id
      )
    );
  };

  const handlePreviousWeek = () => {
    if (currentMatchWeek > 1) {
      setCurrentMatchWeek((prevWeek) => prevWeek - 1);
      handleWeekChange(currentMatchWeek - 1);
    }
  };

  const handleNextWeek = () => {
    if (currentMatchWeek < matchWeek.length) {
      setCurrentMatchWeek((prevWeek) => prevWeek + 1);
      handleWeekChange(currentMatchWeek + 1);
    }
  };

  const renderButton = (week) => (
    <button
      key={week}
      className={`rounded-[6px] basis-1/3 ${potType === 'newPot' &&'mx-auto'} gap-[10%] cursor-pointer ${
        week === currentMatchWeek
          ? "bg-[#2D74C9] text-white text-[12px] px-[3%] py-4 transition-colors duration-800 laptop-s:w-[100px]"
          : "px-[3%] py-4 transition-colors duration-800 wiggle"
      } black p-3 ${
        week === currentMatchWeek
          ? "text-[#2D74C9] text-[12px]"
          : "text-[#9A9A9A] text-[12px] bg-[#F8F8F8] hover:bg-[#d1cfcf]"
      } ${
        week === currentMatchWeek ? "text-[12px] font-semibold" : "text-[12px]"
      }`}
      onClick={() => handleWeekChange(week)}
    >
      <p className="text-nowrap">Match Week {week}</p>
    </button>
  );

  const btnArray = isMobile
    ? [currentMatchWeek]
    : potType !== "newPot"
    ? [currentMatchWeek - 1, currentMatchWeek, currentMatchWeek + 1].filter(
        (week) => week > 0 && week <= matchWeekInfo.length
      )
    : [currentMatchWeek].filter(
        (week) => week > 0 && week <= matchWeekInfo.length
      );

  return (
    <div className="w-full overflow-auto m-auto pl-[2%] flex justify-between items-center">
      {potType !== "newPot" && (
        <img
          src={leftArrowButton}
          className="w-[32px] h-[32px] cursor-pointer"
          onClick={handlePreviousWeek}
        />
      )}

      {btnArray.map(renderButton)}
      {potType !== "newPot" && (
        <img
          src={rigthArrowButton}
          className="w-[32px] h-[32px] cursor-pointer"
          onClick={handleNextWeek}
        />
      )}
    </div>
  );
}

export default Matchweek;
