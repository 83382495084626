import React from "react";
import useFindDevice from "../CustomHooks/FindDevice";

function PotDetail({ fetchPotInfo }) {
  let { isMob, isTab, isLaptop, isLogedIn } = useFindDevice();
  const finalValue =
    fetchPotInfo[0]?.max_pot_value -
    (fetchPotInfo[0]?.max_pot_value / 100) * fetchPotInfo[0]?.admin_fee;
  return (
    <div>
      {isLaptop ? (
        <div className="flex justify-center bg-[#2D74C9] p-2">
          <div className="flex ml-[5%] gap-6">
            <div className="flex text-[white] gap-2">
              <p className="text-[#FFFFFFB2]">Round Id</p>{" "}
              <h2 className="text-[#FFFFFF]">{fetchPotInfo[0]?.pot_id}</h2>
            </div>
            <p className=" text-[#FFFFFFB2]">|</p>
            <div className="flex text-[white] gap-2">
              <p className="text-[#FFFFFFB2]">Pick Id</p>{" "}
              <h2 className="text-[#FFFFFF]">{fetchPotInfo[0]?.pick_id}</h2>
            </div>
            <p className=" text-[#FFFFFFB2]">|</p>
            <div className="flex text-[white] gap-2">
              <p className="text-[#FFFFFFB2]">Match Week</p>{" "}
              <h2 className="text-[#FFFFFF]">{fetchPotInfo[0]?.matchWeek}</h2>
            </div>
            <p className=" text-[#FFFFFFB2]">|</p>
            <div className="flex text-[white] gap-2">
              <p className="text-[#FFFFFFB2]">Round Value</p>{" "}
              <h2 className="text-[#FFFFFF]">
                {/* {fetchPotInfo[0]?.pick_status === "JackPot"
                  ? fetchPotInfo[0]?.potValue
                  : finalValue} */}
                  {finalValue}
              </h2>
            </div>
            <p className=" text-[#FFFFFFB2]">|</p>
            <div className="flex text-[#FFFFFFB2] gap-2">
              <p className="text-[#FFFFFFB2]">Total Picks</p>{" "}
              <h2 className="text-[#FFFFFF]">{fetchPotInfo[0]?.no_of_picks}</h2>
            </div>
            <p className=" text-[#FFFFFFB2]">|</p>
            <div className="flex text-[#FFFFFFB2] gap-2">
              <p className="text-[#FFFFFFB2]">Round Status</p>{" "}
              <h2 className="text-[#FFFFFF]">
                {fetchPotInfo[0]?.pick_status === "JackPot"
                  ? "Voting"
                  : fetchPotInfo[0]?.pick_status}
              </h2>
            </div>
          </div>
        </div>
      ) : isTab ? (
        <div className="flex bg-[#2D74C9] items-center justify-between px-8 p-2 ">
          <div className="flex  gap-4 flex-col">
            <div className="flex text-[white] gap-2 justify-start w-full">
              <p className="text-[#FFFFFFB2] w-[70%]">Round Id</p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%]">
                {fetchPotInfo[0]?.pot_id}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%]">Round Value</p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%]">
                {fetchPotInfo[0]?.potValue}
              </h2>
            </div>
          </div>
          <div className="flex  gap-4 flex-col ">
            <div className="flex text-[white] gap-2 w-full">
              <p className="text-[#FFFFFFB2] w-[90%]">Pick Id</p>{" "}
              <h2 className="text-[#FFFFFF] w-[10%]">
                {fetchPotInfo[0]?.pick_id}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 w-full">
              <p className="text-[#FFFFFFB2] w-[90%]">Total Picks</p>{" "}
              <h2 className="text-[#FFFFFF] w-[2%]">
                {fetchPotInfo[0]?.no_of_picks}
              </h2>
            </div>
          </div>
          <div className="flex gap-4 flex-col">
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%]">Match Week</p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%]">
                {fetchPotInfo[0]?.matchWeek}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%]">Round Status</p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%]">
                {fetchPotInfo[0]?.pick_status}
              </h2>
            </div>
          </div>
          {/* </div> */}
        </div>
      ) : (
        <div className="flex bg-[#2D74C9] items-center gap-4 justify-center p-2">
          <div className="flex ml-[5%] gap-4 flex-col">
            <div className="flex text-[white] gap-2 justify-start w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Round Id
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.pot_id}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Round Value
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.potValue}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Pick Id
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.pick_id}
              </h2>
            </div>
          </div>
          {/* 
          <div className="flex ml-[5%] gap-4 flex-col ">
     
      
          </div> */}
          <div className="flex ml-[5%] gap-4 flex-col">
            <div className="flex text-[white] gap-2 w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Total Picks
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.no_of_picks}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Match Week
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.matchWeek}
              </h2>
            </div>
            <div className="flex text-[white] gap-2 justify-center w-full">
              <p className="text-[#FFFFFFB2] w-[70%] text-[12px] font-interFont font-[500]">
                Round Status
              </p>{" "}
              <h2 className="text-[#FFFFFF] w-[30%] text-[12px] font-interFont text-[600]">
                {fetchPotInfo[0]?.pick_status}
              </h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PotDetail;
